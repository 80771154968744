import {
    createElFromTemplate,
    replaceContainerContent,
    triggerEvent
} from './Utils';

const CONTAINER_SELECTOR = 'form.js-ajax-form';
export const UPDATED_EVENT_NAME = 'inca.form.updated';

class AjaxForm {
    /**
     *
     * @param {HTMLFormElement} el
     */
    constructor(el) {
        this.el = el;

        const onFormSubmit = this.onFormSubmit.bind(this);
        this.el.addEventListener('submit', e => {
            e.preventDefault();
            onFormSubmit();
        });
    }

    /**
     * @param {HTMLFormElement} form
     * @returns {Promise<string>}
     */
    onFormSubmit() {
        const onFetch = html => {
            const form = createElFromTemplate(html).querySelector(
                '#' + this.el.id
            );
            if (form) {
                this.updateContainer(form.childNodes);
            }
        };
        return fetch(this.el.action, {
            method: this.el.method,
            body: new FormData(this.el)
        })
            .then(response => response.text())
            .then(onFetch.bind(this));
    }

    /**
     * @param {HTMLElement|string} body
     */
    updateContainer(body) {
        replaceContainerContent(this.el, body);
        this.triggerLoadedEvent();
    }

    triggerLoadedEvent() {
        triggerEvent(UPDATED_EVENT_NAME, { form: this });
    }
}

export const registerAjaxForm = el => {
    const form = new AjaxForm(el);
    return form;
};

export const registerAjaxForms = (container, selector = CONTAINER_SELECTOR) => {
    const formEls = container.querySelectorAll(selector);
    for (const formEl of formEls) {
        registerAjaxForm(formEl);
    }
};
