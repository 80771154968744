import BoostrapModal from 'bootstrap/js/src/modal';
import {
    addLinksToHead,
    addScriptsToHead,
    createElFromTemplate,
    replaceContainerContent,
    triggerEvent
} from './Utils';
import video from './video';

export const PRELOADING_STATE = 'inca.modal.preloading';
export const PRELOADED_STATE = 'inca.modal.preloaded';
export const LOADED_STATE = 'inca.modal.loaded';
export const LOADED_EVENT_NAME = 'inca.modal.loaded';

/**
 * @property {string} src
 * @property {array} options
 * @property {HTMLElement} container
 * @property {BoostrapModal} innerModal
 * @property {string} state
 * @property {Promise} fetchPromise
 */
class AjaxModal {
    constructor(src, options) {
        this.src = src;
        this.options = options;
        this.container = null;
        this.innerModal = null;
        this.state = null;
        this.fetchPromise = null;
    }

    preload() {
        if (this.state === null) {
            this.state = PRELOADING_STATE;

            const onFetch = html => {
                this.state = PRELOADED_STATE;
                this.container = createElFromTemplate(html);
                this.container.addEventListener(
                    'hidden.bs.modal',
                    this.onHidden.bind(this)
                );
                this.innerModal = new BoostrapModal(
                    this.container,
                    this.options
                );
                this.interceptFormSubmit();
                return this;
            };
            this.fetchPromise = fetch(this.src, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
                .then(response => response.text())
                .then(onFetch.bind(this));
        }

        return this.fetchPromise;
    }

    interceptFormSubmit() {
        const forms = this.container.querySelectorAll('form');
        const onFormSubmit = this.onFormSubmit.bind(this);
        for (const form of forms) {
            form.addEventListener('submit', e => {
                e.preventDefault();
                const form = e.target;
                onFormSubmit(form);
            });
        }
    }

    /**
     * @param {HTMLFormElement} form
     * @returns {Promise<string>}
     */
    onFormSubmit(form) {
        return fetch(this.src, {
            method: form.method,
            body: new FormData(form),
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => response.text())
            .then(html => {
                const container = createElFromTemplate(html);
                this.setBody(container.querySelector('.modal-content'));
                this.interceptFormSubmit();
            });
    }

    load() {
        return this.preload().then(this.onLoaded.bind(this));
    }

    onLoaded() {
        this.state = LOADED_STATE;
        this.triggerLoadedEvent();
        return this;
    }

    triggerLoadedEvent() {
        triggerEvent(LOADED_EVENT_NAME, { modal: this });
    }

    show(triggerEl) {
        if (this.innerModal) {
            this.innerModal.show(triggerEl);
            addScriptsToHead(this.container);
            addLinksToHead(this.container);
        }
    }

    onHidden() {
        if (this.innerModal) {
            this.innerModal.hide();
        }
    }

    toggle() {
        if (this.innerModal) {
            this.innerModal.toggle();
        }
    }

    onHide(event) {
        if (this.container) {
            document.body.removeChild(this.container);
        }
    }

    /**
     * @param {HTMLElement|string} body
     */
    setBody(body) {
        if (this.container) {
            const modalBody = this.container.querySelector('.modal-content');
            replaceContainerContent(modalBody, body);
            this.triggerLoadedEvent();
        }
    }
}

const modals = new Map();

/**
 * @param {HTMLLinkElement} el
 * @returns {AjaxModal}
 */
export const getModal = src => {
    if (!modals.has(src)) {
        modals.set(src, new AjaxModal(src));
    }
    return modals.get(src);
};

/**
 * @type {HTMLLinkElement} el
 */
export const registerTriggerButton = el => {
    if (el.host !== window.location.host) {
        return;
    }
    const modal = getModal(el.href);
    const _trapFocus = function (el) {
        var element = el;
        var KEYCODE_TAB = 9;

        element.addEventListener('keydown', function (e) {
            var isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
            var focusableEls = $(
                'a:not([tabindex="-1"]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), div[role="button"]',
                $(element)
            ).filter(':visible');
            var firstFocusableEl = focusableEls[0];
            var lastFocusableEl = focusableEls[focusableEls.length - 1];
            const isEscapeEvent = function (e) {
                return (
                    e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27
                );
            };

            if (isEscapeEvent(e)) {
                element.querySelector('.btn-close').click();
                return;
            }

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                /* shift + tab */ if (
                    document.activeElement === firstFocusableEl
                ) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } /* tab */ else {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
    };

    modal.preload();
    el.addEventListener('click', e => {
        e.preventDefault();
        const opener = e.target;
        modal.load().then(modal => {
            modal.show(el);
            // init video
            modal.container.addEventListener('shown.bs.modal', () => {
                video.init();
                _trapFocus(modal.container);
            });

            modal.container.addEventListener('hidden.bs.modal', () => {
                if (opener) opener.focus();
                modal.container.remove();
            });
        });
    });
};

export const registerTriggerButtons = container => {
    const ajaxModalTriggerButtons = container.querySelectorAll(
        'a.js-ajax-modal-btn'
    );
    for (const btn of ajaxModalTriggerButtons) {
        registerTriggerButton(btn);
    }
};
