import $ from 'jquery';

window.$ = $;
window.jQuery = $;

const datalayerTracking = (function () {
    function sendDataLayerToGoogleAnalytics() {
        window.dataLayer = window.dataLayer || [];
        const dataLayerTrackElements = [
            ...document.querySelectorAll('.js-datalayer-click-events')
        ];

        if (dataLayerTrackElements) {
            dataLayerTrackElements.map(gaElem => {
                gaElem.addEventListener('click', e => {
                    try {
                        const context = document
                            .querySelector('div[data-context]')
                            .getAttribute('data-context');

                        const tcObject = JSON.parse(
                            gaElem.getAttribute('data-analytics')
                        );

                        if (Object.hasOwn(tcObject, 'click_chapter1')) {
                            if (tcObject.click_chapter1 === '$profile$') {
                                if (context)
                                    tcObject.click_chapter1 = context
                                        .toLowerCase()
                                        .replace(' ', '-');
                            }
                        }

                        // window.dataLayer.push(tcObject);
                        tC.container.reload({
                            events: { click: [{}, tcObject] }
                        });
                    } catch (error) {
                        console.error(
                            'Error parsing JSON for dataLayer:',
                            error
                        );
                    }
                });
            });
        }

        $(document).on('click', '[data-form-identifier] form', function () {
            const tcObject = JSON.parse(
                $(this).parents('[data-form-identifier]').attr('data-analytics')
            );
            const context = document
                .querySelector('div[data-context]')
                .getAttribute('data-context');

            if (Object.hasOwn(tcObject, 'click_chapter1')) {
                if (tcObject.click_chapter1 === 'empty') {
                    if (context)
                        tcObject.click_chapter1 = context
                            .toLowerCase()
                            .replace(' ', '-');
                }
            }

            tC.container.reload({ events: { click: [{}, tcObject] } });
        });

        const form = document.querySelector('.ais-SearchBox-form');
        if (form) {
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                const queryInput = this.querySelector(
                    'input.ais-SearchBox-input'
                );
                if (queryInput) {
                    const searchTerm = queryInput.value.trim();
                    const tcObject = {
                        event: 'search',
                        search_term: searchTerm
                    };

                    tC.container.reload({ events: { click: [{}, tcObject] } });
                    this.submit();
                }
            });
        }
    }

    function _init() {
        document.addEventListener('DOMContentLoaded', function () {
            sendDataLayerToGoogleAnalytics();
        });
    }

    return {
        sendDataLayerToGoogleAnalytics: sendDataLayerToGoogleAnalytics,
        init: _init
    };
})();

export default datalayerTracking;
