const langUris = document.querySelectorAll("link[rel='alternate']");
const languageSelect = document.querySelectorAll('.dropdown-lang');
const buttonLang = document.getElementById('dropdown-lang-btn');
let currentLang = '';
if (buttonLang) {
    currentLang = buttonLang.getAttribute('data-lang');
}
if (langUris.length > 1) {
    languageSelect.forEach(function (select) {
        const ulLang = document.createElement('ul');
        ulLang.classList.add('dropdown-menu');

        const button = select.firstElementChild;
        ulLang.setAttribute('aria-labelledby', button.getAttribute('id'));

        langUris.forEach(function (lang) {
            const langLi = document.createElement('li');
            const langLink = document.createElement('a');
            langLink.classList.add('dropdown-item');
            langLink.href = lang.getAttribute('href');
            const hreflang = lang.getAttribute('hreflang');
            if (hreflang) {
                const langString = hreflang.split('_')[0];
                if (langString) {
                    if (langString === currentLang) {
                        langLi.classList.add('active');
                    }
                    langLink.innerHTML = langString.toUpperCase();
                    langLi.appendChild(langLink);
                    ulLang.appendChild(langLi);
                }
            }
        });
        select.appendChild(ulLang);
    });
}
