/**
 * @param {string} template
 * @param {array} parameters
 * @param {HTMLElement|null} wrapper
 * @returns {HTMLElement}
 */
export const createElFromTemplate = (
    template,
    parameters = {},
    wrapper = null
) => {
    for (const parametersKey in parameters) {
        template = template.replaceAll(
            parametersKey,
            parameters[parametersKey]
        );
    }

    const tmp = document.createElement('div');
    if (wrapper instanceof HTMLElement) {
        tmp.append(wrapper);
        wrapper.innerHTML = template;
    } else {
        tmp.innerHTML = template;
    }

    return tmp.children.item(0);
};

/**
 * @param {HTMLElement} container
 */
export const addScriptsToHead = container => {
    const scriptEls = container.querySelectorAll('script');
    /** @type {HTMLScriptElement} */
    for (const scriptEl of scriptEls) {
        if (
            !document.head.querySelector('script[src="' + scriptEl.src + '"]')
        ) {
            /** @type {HTMLScriptElement} */
            const el = document.createElement('script');
            el.type = scriptEl.type;
            el.src = scriptEl.src;
            document.head.appendChild(el);
        }
    }
};

/**
 * @param {HTMLElement} container
 */
export const addLinksToHead = container => {
    const linkEls = container.querySelectorAll('link');
    /** @type {HTMLLinkElement} */
    for (const linkEl of linkEls) {
        if (!document.head.querySelector('link[href="' + linkEl.href + '"]')) {
            /** @type {HTMLLinkElement} */
            const el = document.createElement('link');
            el.type = linkEl.type;
            el.rel = linkEl.rel;
            el.href = linkEl.href;
            document.head.appendChild(el);
        }
    }
};

/**
 *
 * @param {HTMLElement} container
 * @param {HTMLElement|string} content
 */
export const replaceContainerContent = (container, content) => {
    if (content instanceof NodeList) {
        container.innerHTML = '';
        container.append(...content);
    } else if (content instanceof HTMLElement) {
        container.innerHTML = '';
        container.append(content);
    } else {
        container.innerHTML = content;
    }
    container.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
    });
    addScriptsToHead(container);
    addLinksToHead(container);
};

/**
 *
 * @param {string} name
 * @param {object} detail
 */
export const triggerEvent = (name, detail) => {
    const event = new CustomEvent(name, {
        detail: detail
    });
    document.dispatchEvent(event);
};
