const navPrincipal = (function () {
    function _init() {
        const btnMenu = document.querySelector('.header-inca .js-btn-menu');
        const btnMenuClose = document.querySelector('.fr-nav__close');
        const megaMenu = document.querySelector('.fr-mega-menu');
        const body = document.body;
        const navParent = megaMenu.querySelector('.fr-nav__parent');

        // restore tabindex
        function restoreTabIndexInside(clickedElement) {
            const clickedElementInteractives =
                clickedElement.querySelectorAll('a, button');
            clickedElementInteractives.forEach(element => {
                element.removeAttribute('tabindex');
                element.removeAttribute('disabled');
            });
        }

        function reportWindowSize() {
            if (window.innerWidth >= 992) {
                restoreTabIndexInside(megaMenu);
            } else {
                const collapsibles = document.querySelectorAll(
                    '.fr-mega-menu__collapse.show'
                );
                if (collapsibles) {
                    const activeDialog = collapsibles[collapsibles.length - 1];
                    if (activeDialog)
                        activeDialog.querySelector('.fr-link--back').click();
                }
            }
        }

        // show
        if (btnMenu) {
            btnMenu.addEventListener('click', function () {
                megaMenu.classList.remove('d-none');
                body.classList.add('overflow-hidden');
                // focus nav title
                document.querySelector('.js-nav__title').focus();
                window.addEventListener('resize', reportWindowSize);
            });
        }

        // hide
        if (btnMenuClose) {
            btnMenuClose.addEventListener('click', function () {
                megaMenu.classList.add('d-none');
                body.classList.remove('overflow-hidden');
                btnMenu.focus();
                window.removeEventListener('resize', reportWindowSize);
            });
        }

        // return btn to prev level
        document.querySelectorAll('.fr-link--back').forEach(function (element) {
            element.addEventListener('click', function () {
                const expandedCollapse = this.closest('.collapse.show');

                if (expandedCollapse) {
                    const parentNavItem =
                        expandedCollapse.closest('.fr-nav__item');
                    expandedCollapse.classList.remove('show');

                    if (parentNavItem) {
                        const prevLevel =
                            parentNavItem.closest('.collapse.show');
                        const prevButton = parentNavItem.querySelector(
                            'button.fr-nav__link'
                        );

                        if (prevButton) {
                            prevButton.setAttribute('aria-expanded', 'false');
                        }

                        // restore tabindex for items for mobile
                        if (window.innerWidth < 992) {
                            if (prevLevel) {
                                // restore tabindex for prev level
                                restoreTabIndexInside(prevLevel);
                            } else {
                                // restore tabindex for all
                                restoreTabIndexInside(navParent);
                            }

                            if (prevButton) {
                                prevButton.removeAttribute('disabled');
                            }
                        }
                    }
                }
            });
        });

        // disable multiple collapses at same level
        // and add class fr-collapse--expanded if parent collapse open
        if (megaMenu) {
            megaMenu.addEventListener('show.bs.collapse', function (event) {
                const clickedElement = event.target;
                const clickedBtn = document.querySelector(
                    "[data-bs-target='#" + $(event.target).attr('id') + "']"
                );

                if (megaMenu.contains(clickedElement)) {
                    // prevent tabulate invisible items
                    if (navParent && window.innerWidth < 992) {
                        if (!clickedBtn.classList.contains('last-column-btn')) {
                            const allInteractiveElements =
                                navParent.querySelectorAll('a, button');

                            // add tabindex for all elements
                            allInteractiveElements.forEach(element => {
                                if (!clickedElement.contains(element)) {
                                    element.setAttribute('tabindex', '-1');
                                    element.setAttribute('disabled', 'true');
                                }
                            });

                            clickedBtn.setAttribute('disabled', 'true');

                            // restore tabindex for current
                            restoreTabIndexInside(clickedElement);
                        }
                    }

                    if (clickedElement.classList.contains('parent-collapse')) {
                        clickedElement.classList.add('fr-collapse--expanded');
                        megaMenu.classList.add('menu-open');
                    }

                    const parentNavItem =
                        clickedElement.closest('.fr-nav__item');
                    if (parentNavItem) {
                        const parentNavItemDepth =
                            parentNavItem.getAttribute('data-depth');
                        const siblings = Array.from(
                            parentNavItem.parentElement.querySelectorAll(
                                '.fr-nav__item[data-depth="' +
                                    parentNavItemDepth +
                                    '"]'
                            )
                        );
                        siblings.forEach(function (sibling) {
                            const siblingCollapse =
                                sibling.querySelector('.collapse.show');
                            if (siblingCollapse) {
                                siblingCollapse.classList.remove('show');
                            }
                        });
                    }
                }
            });

            const headerNavigation =
                document.querySelector('#header-navigation');

            if (headerNavigation) _menuTrapFocus(headerNavigation);
        }
    }

    function _menuTrapFocus(el) {
        const element = el;
        const KEYCODE_TAB = 9;

        element.addEventListener('keydown', function (e) {
            const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
            const focusableEls = Array.from(
                element.querySelectorAll(
                    'a:not([tabindex="-1"]), ' +
                        'button:not([disabled]), ' +
                        'textarea:not([disabled]), ' +
                        'input[type="text"]:not([disabled]), ' +
                        'input[type="radio"]:not([disabled]), ' +
                        'input[type="checkbox"]:not([disabled]), ' +
                        'select:not([disabled]), ' +
                        'div[role="button"]'
                )
            ).filter(function (el) {
                return el.offsetWidth > 0 || el.offsetHeight > 0; // filter out elements that are not visible
            });
            const firstFocusableEl = focusableEls[0];
            const lastFocusableEl = focusableEls[focusableEls.length - 1];
            const isEscapeEvent = function (e) {
                return (
                    e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27
                );
            };

            if (isEscapeEvent(e)) {
                element.querySelector('.fr-nav__close').click();
                return;
            }

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                /* shift + tab */ if (
                    document.activeElement === firstFocusableEl
                ) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } /* tab */ else {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
    }

    return { init: _init };
})();

export default navPrincipal;
