const navSticky = (function () {
    function _init() {
        let prevScrollpos = window.scrollY;
        let headerHeight = 252;

        if (window.innerWidth < 768) {
            headerHeight = 168;
        }

        window.onscroll = function () {
            const currentScrollPos = window.scrollY;
            const header = document.getElementById('header');
            if (header) {
                if (prevScrollpos > currentScrollPos) {
                    header.style.top = '0';

                    if (currentScrollPos < headerHeight) {
                        header.classList.remove('header-sticky');
                    }
                } else {
                    header.style.top = -headerHeight + 'px';

                    if (currentScrollPos > headerHeight) {
                        header.classList.add('header-sticky');
                    }
                }
            }
            prevScrollpos = currentScrollPos;
        };
    }

    return { init: _init };
})();

export default navSticky;
