import { v4 as uuidv4 } from 'uuid';

/**
 * Token configuration
 */
const TOKEN_CONFIG = {
    PREFIX: 'anonymous',
    SEPARATOR: '-',
    STORAGE_KEY: 'userToken'
};

/**
 * Generates or retrieves a user token for analytics purposes.
 * Falls back to generating a new token if localStorage is unavailable.
 * @returns {string} The user token
 */
export const getUserToken = async () => {
    try {
        if (localStorage === undefined) {
            return generateUniqueToken();
        }
        const existingToken = localStorage.getItem(TOKEN_CONFIG.STORAGE_KEY);
        if (existingToken) {
            return existingToken;
        }

        const newToken = generateUniqueToken();
        try {
            localStorage.setItem(TOKEN_CONFIG.STORAGE_KEY, newToken);
        } catch (storageError) {
            console.warn('Failed to store user token:', storageError);
        }
        return newToken;
    } catch (error) {
        console.warn('localStorage is not available:', error);
        return generateUniqueToken();
    }
};

/**
 * Generates a unique token using UUID v4
 * @param {string} [prefix=TOKEN_CONFIG.PREFIX] - Custom prefix for the token
 * @returns {string} A unique token in format: prefix-uuid
 */
const generateUniqueToken = (prefix = TOKEN_CONFIG.PREFIX) => {
    return `${prefix}${TOKEN_CONFIG.SEPARATOR}${uuidv4()}`;
};

let currentUserId = null;
/**
 * Function to fetch the current user and retrieve their user ID.
 * @returns {Promise<number|null>} The user ID or null in case of an error.
 */
const fetchCurrentUserId = async () => {
    if (currentUserId === null) {
        currentUserId = new Promise((resolve, reject) => {
            fetch('/current-user-id', {
                method: 'GET'
            })
                .then(response => response.json())
                .then(data => {
                    const userId = data.id;

                    if (typeof userId === 'number') {
                        resolve(userId);
                    } else {
                        resolve(null);
                    }
                });
        });
    }

    return currentUserId;
};

/**
 * @returns {Promise<null|string>}
 */
export const getAuthenticatedUserToken = async () => {
    try {
        const userId = await fetchCurrentUserId();
        if (userId) {
            return `user-${userId}`;
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error generating authenticated user token:', error);
        return null;
    }
};
