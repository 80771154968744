import { autocomplete } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { searchClient } from './API';

const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: `${window.algoliaConfig.defaultIndexName}_query_suggestions`,
    getSearchParams() {
        return {
            hitsPerPage: 10
        };
    }
});

export function createAutocomplete(container) {
    if (!(container instanceof HTMLElement)) {
        container = document.querySelector(container);
    }

    if (typeof container === 'undefined' || container === null) {
        return;
    }

    const searchUrl = new URL(container.dataset.url);

    return autocomplete({
        container: container,
        panelContainer: container,
        placeholder: Translator.trans(
            /* @Desc("Rechercher...") */ 'search.autocomplete.placeholder',
            {},
            'messages'
        ),
        translations: {
            submitButtonTitle: Translator.trans(
                /* @Desc("Rechercher") */ 'search.autocomplete.submit',
                {},
                'messages'
            ),
            clearButtonTitle: Translator.trans(
                /* @Desc("Réinitialiser") */ 'search.autocomplete.reset',
                {},
                'messages'
            )
        },
        openOnFocus: true,
        insights: true,
        plugins: [querySuggestionsPlugin],
        detachedMediaQuery: 'none',
        onSubmit: ({ state }) => {
            searchUrl.searchParams.set(
                `${window.algoliaConfig.defaultIndexName}[query]`,
                state.query
            );
            tC.container.reload({
                events: {
                    click: [
                        {},
                        {
                            click_name: 'clic-sur-rechercher',
                            click_type: 'click.action'
                        }
                    ]
                }
            });
            window.location.href = searchUrl;
        }
    });
}
