import 'bootstrap';
import navSticky from './components/navSticky';
import navPrincipal from './components/navPrincipal';
import './components/language-selector';
import * as Modal from './components/ajax_modal';
import * as AjaxForm from './components/ajax_form';
import CaptchaEtat from '../../../public/bundles/almaviacxcaptchetat/js/captchetat-widget';
import { createAutocomplete } from './components/Algolia/AutoComplete';
import datalayer from './components/datalayer-tracking';
import {
    getAuthenticatedUserToken,
    getUserToken
} from './components/Algolia/Utils';
import aa from 'search-insights';

navSticky.init();
navPrincipal.init();
CaptchaEtat.init();
datalayer.init();

export const load = container => {
    Modal.registerTriggerButtons(container);
    AjaxForm.registerAjaxForms(container);
};

load(document);

getUserToken().then(id => aa('setUserToken', id));
getAuthenticatedUserToken().then(id => aa('setAuthenticatedUserToken', id));
createAutocomplete('#searchModal .algolia-autocomplete');

document.addEventListener(
    Modal.LOADED_EVENT_NAME,
    /**
     * @param {CustomEvent} event
     */
    event => {
        /**
         * @type {AjaxModal}
         */
        const modal = event.detail.modal;

        load(modal.container);
    }
);

document.addEventListener(
    AjaxForm.UPDATED_EVENT_NAME,
    /**
     * @param {CustomEvent} event
     */
    event => {
        /**
         * @type {AjaxForm}
         */
        const form = event.detail.form;

        load(form.el);
    }
);
