import algoliasearch from 'algoliasearch/lite';

export const searchClient = algoliasearch(
    window.algoliaConfig.appId,
    window.algoliaConfig.apiKey
);
export const userTokenValue =
    typeof userToken !== 'undefined' ? userToken : 'incaUserToken';
export const authenticatedUserTokenValue =
    typeof authenticatedUserToken !== 'undefined'
        ? authenticatedUserToken
        : 'incaAuthenticatedUserToken';
